import React, { useState } from 'react'
import Footer from '../../components/footer'
import '../../styles/solution/game.less'
import SEO from '../../components/SEO'
import { ConsultationPopButton } from '../../components/form-biz'
import { Tab } from '../../components/ui'
import Drop from '../../components/drop'
import '../../styles/solution/media.less'
import { SolutionTopBanner, ProductBlog } from '../../components/block'
import { TopContent } from '../ebook/edge-cloud-media-entertainment'
import { graphql } from 'gatsby'
const loadText = require('src/utils').loadText
import { lang } from '../../utils'
import { Button } from '../../components/ui'
const url_data = loadText('url')
const navigate = (url) => window.open(url)
const data = loadText('media')
export const Video = ({ src, title }) => {
  return (
    <div className="media-video">
      <div className="media-video-bg">
        <div className="media-video-content">
          <iframe
            className="media-video-iframe only-desktop"
            frameBorder="0"
            width="612px"
            height="340px"
            src={src}
          ></iframe>
          <iframe
            className="media-video-iframe only-mobile"
            frameBorder="0"
            width="544px"
            height="302px"
            src={src}
          ></iframe>
          <h2 className="media-video-text">{title}</h2>
        </div>
      </div>
    </div>
  )
}
const Client = () => {
  return (
    <div className="game-client">
      <Drop color="rgb(245,230,255)" style={{ top: '0' }} />
      <div className="media-client-bg">
        <div className="game-client-content page-middle">
          <h2 className="font-36 semi-bold text-center game-client-title white">
            {data.client.title}
          </h2>
          <div className="game-client-item">
            <div className="left">
              <picture>
                <source
                  srcSet="/solution/media/cpic-m.png 640w"
                  media="(max-width: 640px)"
                />
                <img src="/solution/media/cpic.png" alt="client_pic" />
              </picture>
            </div>

            <div className="right">
              <div className="top">
                <div>
                  <img src="/solution/media/zego.svg" alt="zego" />
                </div>
                {data.client.stats.map(({ desc, value }) => (
                  <div>
                    <img
                      src={`/solution/media/${value}.svg`}
                      alt={`${value}`}
                    />
                    <div className="mt-8">{desc}</div>
                  </div>
                ))}
              </div>
              <div className="bottom">
                <div className="bottom-content">
                  <div className="quote">{data.client.quote}</div>
                  <div className="mt-24">
                    <img src="/solution/media/zego-sign.svg" alt="sign" />
                  </div>
                  <div className="font-12 jobTitle mt-8">
                    {data.client.jobTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export const Products = () => {
  const ProductItem = ({ name, description, url, pic }) => (
    <div className="media-products-item" onClick={() => navigate(url)}>
      <img src={pic} alt={name} style={{ width: '110px' }} />
      <div>
        <h3 className="font-16 title semi-bold mt-16">{name}</h3>
        <div className="mt-8 description">{description}</div>
      </div>
    </div>
  )
  return (
    <div className="media-products-wrapper">
      <Drop style={{ top: '-1px' }} />
      <div className="media-products">
        <h2 className="font-36 t-color semi-bold text-center title">
          {data.products.title}
        </h2>
        <div className="media-products-subtitle">{data.products.subtitle}</div>
        <div className="media-products-sectitle">
          <span>{data.products.iaas.title}</span>
        </div>
        <div className="media-products-row">
          {data.products.iaas.content.map(({ name, description, _key }) => (
            <ProductItem
              name={name}
              description={description}
              url={url_data[_key].link}
              pic={`/header/${_key}.svg`}
            />
          ))}
        </div>
        <div className="media-products-sectitle">
          <span>{data.products.paas.title}</span>
        </div>
        <div className="media-products-row row2">
          {data.products.paas.content.map(({ name, description, _key }) => (
            <ProductItem
              name={name}
              description={description}
              url={url_data[_key].link}
              pic={`/header/${_key}.svg`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const BottomBanner = () => {
  return (
    <div className="media-bottomBanner-wrapper">
      <div className="media-bottomBanner">
        <div className="media-bottomBanner-bg full-bg">
          <div className="media-bottomBanner-content page-middle">
            <h3 className="font-36 t-color semi-bold title">
              {data.bottomBanner.title}
            </h3>
            <ConsultationPopButton source="media">
              {data.bottomBanner.buttonText}
            </ConsultationPopButton>
          </div>
        </div>
      </div>
    </div>
  )
}
const Feature = () => {
  const bgColors = [
    'linear-gradient(114deg, #FAF4FF 0%, #F4E6FF 100%)',
    'linear-gradient(90deg, #F1FBFB 0%, #DEF6F5 100%)',
    'linear-gradient(90deg, #F2FBFF 0%, #E1F5FF 100%)',
    'linear-gradient(156deg, #FFFBE7 0%, #FFF2C0 100%)',
  ]
  const headerColors = ['#CF96FF', '#00CBC1', '#4EC3FE', '#FFCB00']
  return (
    <div className="media-feature">
      <h2 className="font-36 semi-bold t-color text-center">
        {data.feature.title}
      </h2>
      <div className="media-feature-item-wrapper">
        {data.feature.content.map(({ title, content }, idx) => (
          <>
            <div
              style={{ background: bgColors[idx] }}
              className="media-feature-item only-desktop"
            >
              <img src={`/solution/media/feature-${idx + 1}.svg`} alt="" />
              <div className="media-feature-item-content">
                <h3 className="font-20 semi-bold t-color">{title}</h3>
                <div className="mt-16">{content}</div>
              </div>
            </div>
            <div
              style={{ background: bgColors[idx] }}
              className="media-feature-item-m only-mobile"
            >
              <div
                className="media-feature-item-m-header"
                style={{ background: headerColors[idx] }}
              >
                <Drop color={headerColors[idx]} style={{ bottom: '-24px' }} />
                <div className="title">{title}</div>
                <img src={`/solution/media/feature-${idx + 1}.svg`} alt="" />
              </div>
              <div className="content">{content}</div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
const Architecture = () => {
  const Item = ({ pic, features }) => (
    <div className="media-architecture-item">
      <img src={pic} alt="architecture-sample" />
      <ul>
        <div className="font-36 t-color semi-bold mb-32">
          {data.architecture.features}
        </div>
        {features.map((feature) => (
          <li className="font-20">{feature}</li>
        ))}
      </ul>
    </div>
  )
  const [active, setActive] = useState('0')
  return (
    <div className="media-architecture">
      <h2 className="font-36 semi-bold t-color text-center">
        {data.architecture.title}
      </h2>
      <div className="media-architecture-content">
        <Tab
          onChange={(key) => {
            setActive(key)
          }}
          active={active}
          tabs={data.architecture.content.map(({ name, architectures }, i) => ({
            key: i + '',
            name: name,
            component: (
              <Item
                pic={`/solution/media/architecture-${lang}-${i + 1}.svg`}
                // architectures={architectures}
                features={architectures}
              />
            ),
          }))}
        />
      </div>
    </div>
  )
}
export default function Home({ data: graphqlData }) {
  const { allStrapiPosts } = graphqlData
  return (
    <div className="media">
      <SEO {...data.seo} featuredImage="/featuredImage/media.jpg" />
      <SolutionTopBanner
        {...data.topBanner}
        bgColor="linear-gradient(180deg, #F6F7FE 0%, #E7E6FF 100%)"
        source="media"
        backgroundAnimated={true}
        pic={{
          src: '/solution/media/topBanner-pic.png',
          style: { position: 'absolute', bottom: '90px', right: '109px' },
        }}
        extraButton={
          <Button
            // buttonlassName="start-btn"
            size="l"
            className="white"
            onClick={() => {
              window.open(
                lang === 'en'
                  ? '/ebook/edge-cloud-media-entertainment/'
                  : '/downloads'
              )
            }}
          >
            {data.topBanner.buttonText2}
          </Button>
        }
        bannerPic={{
          src: '/solution/media/topBanner-bg-m.svg',
          style: {
            backgroundPosition: 'top center',
          },
        }}
        bgPic="/solution/media/topBanner-bg.svg"
      />
      <Feature />
      <Products />
      <Architecture />
      <div
        className="ebook"
        onClick={() => {
          navigate('/ebook/edge-cloud-media-entertainment/')
        }}
      >
        <div className="topBanner">
          <TopContent />
        </div>
      </div>
      <Client />
      <Video
        src="https://www.youtube.com/embed/uiuyHIodbyk"
        title={data.video.title}
      />
      <BottomBanner />
      <ProductBlog
        title={data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        tags: { elemMatch: { name: { eq: "Media and Entertainment" } } }
        author: {}
        published: { eq: true }
      }
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          wp_date
          created_at
          content
          title
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
  }
`
