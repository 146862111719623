import React from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import SEO from '../../../components/SEO'
import '../../../styles/ebook.less'
import { Button } from '../../../components/ui'
import { EbookPageForm } from '../../../components/form-biz'
import { navigate } from 'gatsby-link'
const loadText = require('src/utils').loadText
const data = loadText('ebook')
export const TopContent = () => {
  const { topBanner } = data
  return (
    <div className="topBanner-content">
      <div className="left">
        <div className="title">{topBanner.title}</div>
        <h2 className="subtitle mt-24">{topBanner.subtitle}</h2>
        <div className="description mt-24">{topBanner.description}</div>
      </div>
      <div className="right">
        <img src="/ebook/topBanner-pic.png" alt="ebook" />
      </div>
    </div>
  )
}
const TopBanner = () => {
  return (
    <div className="topBanner">
      <div className="topBanner-bg">
        <div className="headerContainer ">
          <Header logo="/logo-blue.svg" />
        </div>
        <TopContent />
      </div>
    </div>
  )
}
const Content = () => {
  return (
    <div className="content-wrapper">
      <div className="content-bg">
        <div className="content">
          <div className="left">
            {data.content.map((item) => (
              <p>{item}</p>
            ))}
          </div>
          <div className="right">
            <div className="title">{data.form.title}</div>
            <EbookPageForm />
          </div>
        </div>
      </div>
    </div>
  )
}
const B1 = () => {
  return (
    <div className="b1">
      {data.b1.map(({ title, subtitle, description, btnText, link }, i) => (
        <div className="b1-item">
          <picture>
            <source
              srcSet={`/ebook/b1-${i + 1}-m.png 640w `}
              media="(max-width: 640px)"
            />
            <img src={`/ebook/b1-${i + 1}.png`} alt={title} />
          </picture>
          <div className="right">
            <div className="b1-item-title">{title}</div>
            <div className="b1-item-subtitle">{subtitle}</div>
            <div className="b1-item-description">{description}</div>
            <Button
              width={320}
              className="only-desktop"
              onClick={() => {
                navigate(link)
              }}
            >
              {btnText}
            </Button>
            <Button
              width={496}
              className="only-mobile"
              onClick={() => {
                navigate(link)
              }}
            >
              {btnText}
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}
export default function Home() {
  return (
    <div className="ebook">
      <SEO {...data.seo} featuredImage="/featuredImage/zenlayer.jpg" />
      <TopBanner />
      <Content />
      <B1 />
      <Footer />
    </div>
  )
}
