import React, { useState } from 'react'
import Footer from '../../components/footer'
import { graphql } from 'gatsby'
import '../../styles/solution/game.less'
import '../../styles/game-ebook.less'
import SEO from '../../components/SEO'
import { ConsultationPopButton } from '../../components/form-biz'
import { Tab } from '../../components/ui'
import Drop from '../../components/drop'
import { Button } from '../../components/ui'
import { SolutionTopBanner } from '../../components/block'
import { display, lang } from '../../utils'
import { Products, Video } from './media-entertainment'
const loadText = require('src/utils').loadText
import { ProductBlog } from '../../components/block'
const data = loadText('gaming')
const ebookdata = loadText('game-ebook')
const EbookTopBanner = () => (
  <div className="game-ebook-topBanner">
    <div className="game-ebook-topBanner-bg">
      <div className="game-ebook-topBanner-content">
        <div className="left">
          <div className="title">{ebookdata.topBanner.title}</div>
          <div className="subtitle">{ebookdata.topBanner.subtitle}</div>
          <div className="mt-32">
            <Button
              size="l"
              onClick={() => {
                window.open('/ebook/game/')
              }}
            >
              Access the eBook
            </Button>
          </div>
        </div>
        <div className="right">
          <img src="/game-ebook/topBanner-pic.png" alt="icon" />
        </div>
      </div>
    </div>
  </div>
)
const Feature = () => {
  return (
    <div className="game-feature">
      <h2 className="font-36 semi-bold t-color">{data.feature.title}</h2>
      <div className="game-feature-itemContainer">
        {data.feature.content.map(
          ({ name, desc, color, bg, hoverColor, hoverDesc, stats }, idx) => (
            <div>
              <div className="game-feature-item" style={{ background: color }}>
                <div className="game-feature-itemContent">
                  <div className="game-feature-itemNormal">
                    <div>
                      <img
                        src={`/solution/game/feature-${idx + 1}.svg`}
                        alt={name}
                      />
                    </div>
                    <h3
                      className="semi-bold font-20 t-color mt-24"
                      style={{ height: '84px' }}
                    >
                      {name}
                    </h3>
                    <div style={{ lineHeight: '24px' }}>{desc}</div>
                  </div>
                  <div className="game-feature-itemHover">
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{ height: '184px', background: `url(${bg})` }}
                      >
                        <div className="font-20 semi-bold title">
                          <div>{name}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="font-36 semi-bold t-color mt-24"
                      // style={{ marginTop: "24px" }}
                    >
                      {stats}
                    </div>
                    <div
                      className="mt-24"
                      style={{ padding: '0 36px', lineHeight: '24px' }}
                      dangerouslySetInnerHTML={{ __html: hoverDesc }}
                    />
                  </div>
                </div>
              </div>
              <div className="game-feature-item-m">
                <div style={{ background: hoverColor }} className="header">
                  <div className="name">{name}</div>
                  <div>
                    <img
                      src={`/solution/game/feature-${idx + 1}.svg`}
                      alt={name}
                    />
                  </div>
                </div>
                <div style={{ background: color }} className="detail">
                  <Drop color={hoverColor} size="l" style={{ top: '-1px' }} />

                  <div className="description">{desc}</div>
                  <div className="stats mt-24">{stats}</div>
                  <div
                    className="content mt-24"
                    dangerouslySetInnerHTML={{ __html: hoverDesc }}
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}
export const Client = ({ children }: { children?: React.ReactChild }) => {
  return (
    <div className="game-client">
      <Drop color="#fff" style={{ top: '-1px' }} />
      <div className="game-client-bg">
        <div className="game-client-content page-middle">
          <h2 className="font-36 semi-bold text-center game-client-title white">
            {data.client.title}
          </h2>
          <div className="game-client-item">
            <div className="left">
              <picture>
                <source
                  srcSet="/solution/game/cpic-m.png 640w"
                  media="(max-width: 640px)"
                />
                <img src="/solution/game/cpic.png" alt="client_pic" />
              </picture>
            </div>
            <div className="right">
              <div className="top">
                <div>
                  <div className="only-desktop">
                    <Drop
                      color="#fff"
                      direction="left"
                      style={{ left: '-36px', top: '58px' }}
                    />
                  </div>
                  <img src="/solution/game/youmi.svg" alt="youmi" />
                  <div className="font-20 semi-bold t-color">
                    {data.client.company}
                  </div>
                </div>
                {data.client.stats.map(({ desc, value }) => (
                  <div>
                    <img src={`/solution/game/${value}.svg`} alt={`${value}`} />
                    <div className="mt-8">{desc}</div>
                  </div>
                ))}
              </div>
              <div className="bottom">
                <div className="bottom-content">
                  <div className="quote">{data.client.quote}</div>
                  <div className="mt-24">
                    <img src="/solution/game/youmi-sign.svg" alt="sign" />
                  </div>
                  <div className="font-12 jobTitle mt-8">
                    {data.client.jobTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
const BottomBanner = () => {
  return (
    <div className="game-bottomBanner">
      <div className="game-bottomBanner-bg full-bg">
        <div className="game-bottomBanner-content page-middle">
          <h2
            className="font-36 t-color semi-bold title"
            style={{ maxWidth: '913px' }}
          >
            {data.bottomBanner.title}
          </h2>
          <ConsultationPopButton source="gaming">
            {data.bottomBanner.buttonText}
          </ConsultationPopButton>
        </div>
      </div>
    </div>
  )
}
const Architecture = () => {
  const [active, setActive] = useState('0')
  const Item = ({ img, architectures, results }) => (
    <div className="game-architecture-item">
      <div className="game-architecture-pic">
        <img src={img} alt="architecture" />
      </div>
      <div className="game-architecture-itemContent mt-56">
        <ul>
          <h3 className="font-36 t-color semi-bold mb-32">
            {data.architecture.architecture}
          </h3>
          {architectures.map((t: string) => (
            <li className="mb-32 font-20">{t}</li>
          ))}
        </ul>
        <ul>
          <h3 className="font-36 t-color semi-bold mb-32">
            {data.architecture.results}
          </h3>
          {results.map((t: string) => (
            <li className="mb-32 font-20">{t}</li>
          ))}
        </ul>
      </div>
    </div>
  )
  const ItemM = ({ img, architectures, results }) => {
    const [active, setActive] = useState(0)
    const contents = [
      {
        name: data.architecture.architecture,
        content: architectures,
      },
      {
        name: data.architecture.results,
        content: results,
      },
    ]
    return (
      <div>
        <div className="game-architecture-item">
          <div className="game-architecture-pic">
            <img src={img} alt="architecture" className="mobile-padding" />
          </div>
          <div className="game-architecture-itemContent">
            <div className="header">
              {contents.map(({ name }, i) => (
                <div
                  className={i === active ? 'active' : ''}
                  onClick={() => {
                    setActive(i)
                  }}
                >
                  {name}
                  <Drop
                    direction="up"
                    color="#10aafd"
                    width={30}
                    style={{ bottom: '-1px' }}
                  />
                  {/* <div className="drop"> */}
                  {/* <div></div> */}
                  {/* <div></div> */}
                  {/* </div> */}
                </div>
              ))}
            </div>
            {contents.map(({ content }, i) => (
              <ul
                className={`game-architecture-text ${
                  i == active ? 'active' : ''
                }`}
              >
                {content.map((t: string) => (
                  <li className="mt-24 font-20">{t}</li>
                ))}
              </ul>
            ))}
            {/* <ul>
              {contents[active].content.map((t: string) => (
                <li className="mt-24 font-20">{t}</li>
              ))}
            </ul> */}
            {/* <ul>
              <div className="font-36 t-color semi-bold mb-32">
                {data.architecture.results}
              </div>
              {results.map((t: string) => (
                <li className="mb-32 font-20">{t}</li>
              ))}
            </ul> */}
          </div>
        </div>
      </div>
    )
  }
  const activeItem = data.architecture.content[parseInt(active)]
  return (
    <div className="game-architecture full-bg">
      <div className="page-middle">
        <div className="game-architecture-content">
          <h2 className="font-36 semi-bold text-center t-color">
            {data.architecture.title}
          </h2>
          <div className="text-center mt-56 only-desktop">
            <div>
              <Tab
                onChange={(key) => {
                  setActive(key)
                }}
                active={active}
                tabs={data.architecture.content.map(
                  ({ name, type, architectures, results }, i) => ({
                    key: i + '',
                    name: name,
                    component: (
                      <Item
                        img={`/solution/game/architecture-${i + 1}-${lang}.svg`}
                        architectures={architectures}
                        results={results}
                      />
                    ),
                  })
                )}
              />
            </div>
          </div>
          <div className="only-mobile">
            <div className="game-architecture-header">
              {data.architecture.content.map(({ name }, i) => (
                <div
                  className={`semi-bold ${i + '' === active ? 'active' : ''}`}
                  onClick={() => {
                    setActive(i + '')
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
            <ItemM
              img={`/solution/game/architecture-${
                parseInt(active) + 1
              }-${lang}-m.svg`}
              architectures={activeItem.architectures}
              results={activeItem.results}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Home({ data: { allStrapiPosts } }) {
  // const { allStrapiPosts } = data
  return (
    <div className="game">
      <SEO {...data.seo} featuredImage="/featuredImage/game.jpg" />

      <SolutionTopBanner
        {...data.topBanner}
        source="gaming"
        backgroundAnimated={true}
        pic={{
          src: '/solution/game/topbanner-pic.png',
          style: { position: 'absolute', bottom: '90px', right: '109px' },
        }}
        bannerPic={{
          src: '/solution/game/topBanner-bg-m.svg',
          style: {
            backgroundPosition: 'top center',
          },
        }}
        bgPic="/solution/game/topBanner-bg.svg"
      />
      {/* <div className="game-topContainer">
        <div className="game-topWrapper page-middle">
          <Header logo="/logo-blue.svg" />
          <TopIntro />
        </div>
      </div> */}
      <Feature />
      <Products />
      <Architecture />

      <Client>
        <div className="game-client-2 page-middle">
          <img
            src="/solution/game/net-logo.png"
            alt="next"
            className="net-logo"
          />
          <div className="quote-wrapper">
            <div className="only-desktop">
              <Drop
                color="#c5ebff"
                direction="left"
                style={{ left: '-36px', top: '50px' }}
              />
            </div>
            <div className="only-mobile">
              <Drop
                color="rgb(41,175,249)"
                // direction="left"
                // style={{ left: "-36px", top: "50px" }}
              />
            </div>
            <div className="quote-bg">
              <div className="quote">{data.client.quote2}</div>
              <img
                src="/solution/game/net-sign.svg"
                alt="sign"
                className="sign"
              />
              <div className="jobTitle">{data.client.jobTitle2}</div>
            </div>
          </div>
        </div>
      </Client>
      {lang === 'en' && <EbookTopBanner />}

      {/* <Solution
        title={data.solution.title}
        description={data.solution.description}
      /> */}
      <Video
        title={data.video.title}
        src="https://www.youtube.com/embed/f_eEGcXKyVs?t=713s"
      />
      <div style={{ backgroundColor: '#f5f9ff' }}>
        <BottomBanner />
      </div>
      <ProductBlog
        title={data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Gaming" } } }
        author: {}
        published: { eq: true }
      }
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          wp_date
          created_at
          content
          title
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
  }
`
